import React, { ReactNode } from 'react';
import {
  DxCard,
  DxContainer,
  DxMediaQueryValue,
  DxSpacer,
} from '@dvag/design-system-react';
import Footer from '../Footer';

import style from './Layout.module.css';

import FooterSelectionPage from '../Footer/FooterSelectionPage';

interface LayoutProps {
  children: ReactNode;
  isSelection?: boolean;
}

const Layout = ({ children, isSelection }: LayoutProps) => {
  const FooterComponent = isSelection ? FooterSelectionPage : Footer;

  return (
    <div id="layout" className={style.page_container}>
      {isSelection ? (
        <>
          <DxSpacer>
            <DxMediaQueryValue
              mq1="16v"
              mq2="16v"
              mq3="16v"
              mq4="16v"
              mq5="32v"
              property="size"
            />
          </DxSpacer>
          <DxContainer color="transparent" className={style.card_container}>
            <DxCard className={style.children_content_selection}>
              {children}
            </DxCard>
          </DxContainer>
        </>
      ) : (
        <DxContainer type="page" className={style.content_container}>
          <DxContainer className={style.styled_image}>
            <DxContainer color="transparent" className={style.card_container}>
              <DxCard className={style.children_content}>{children}</DxCard>
            </DxContainer>
          </DxContainer>
        </DxContainer>
      )}
      <FooterComponent />
    </div>
  );
};
Layout.defaultProps = {
  isSelection: false,
};

export default Layout;
